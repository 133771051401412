import { Grid } from "semantic-ui-react";
import IdleTimeout from "@itm21st/idle-timeout-react";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";
import React from "react";
import TermsConditions from "./TermsConditions";
import { ToastContainer } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";

const Layout: React.FC = () => {
    const { logout, isAuthenticated } = useAuth0();
    return (
        <>
            <Navbar />
            <Grid padded="horizontally">
                <Grid.Row centered>
                    <Grid.Column tablet={16} widescreen={16}>
                        <ToastContainer theme="colored" />
                        <Outlet />
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <IdleTimeout onIdle={() => logout()} />
            {isAuthenticated && <TermsConditions />}
        </>
    );
};

export default Layout;
