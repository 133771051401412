import { Button, Modal } from "semantic-ui-react";
import React, { useEffect, useState } from "react";

import { termsServiceURL } from "../services/Constants";
import { useAuth0 } from "@auth0/auth0-react";
import useSproc from "../hooks/useSproc";

const TermsConditions: React.FC = () => {
    const { logout } = useAuth0();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const sproc = useSproc();

    const setTCAgreement = async () => {
        try {
            await sproc("SetConnectClientWebGeneralTermsConditionsDate");
            setIsModalOpen(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const data = await sproc("GetConnectClientWebGeneralTermsConditionsDate");
                setIsModalOpen(data.length === 0);
            } catch (error) {
                console.error(error);
            }
        })();
    }, [sproc]);

    return (
        <>
            <Modal
                open={isModalOpen}
                closeOnDimmerClick={false}
                closeOnEscape={false}
                onClose={() => {
                    setIsModalOpen(false);
                }}
            >
                <Modal.Header>Terms of Service</Modal.Header>
                <Modal.Content>
                    Please{" "}
                    <a style={{ textDecoration: "underline" }} href={termsServiceURL} rel="noopener noreferrer" target="_blank">
                        click here
                    </a>{" "}
                    to view the Terms of Service. Click "Accept" if you have read and agree with the Terms of Service.
                    <br />
                    <br />
                    Last update: 11/15/2022
                </Modal.Content>
                <Modal.Actions>
                    <Button basic negative icon="times" content="Decline" onClick={() => logout()} />
                    <Button positive icon="checkmark" content="Accept" onClick={() => setTCAgreement()} />
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default TermsConditions;
